import axios from 'axios';
import { useEffect, useState } from 'react';

const Countdown = ({ defaultTime = 600000, astroID = false, cancelRequest = () => { }, id = 0, method = "" }) => {
  const [time, setTime] = useState(() => defaultTime);
  const [timeString, setTimeString] = useState(() => "");

  useEffect(() => {
    const interval = setInterval(async () => {
      if (time <= 0) {
        cancelRequest("timeout");
        clearInterval(interval);
        return;
      } else {
        const minutes = Math.floor(time / 60000);
        const seconds = ((time % 60000) / 1000).toFixed(0);
        const string = `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        const minus = document?.visibilityState === "hidden" ? 2000 : 1000;
        setTime(time => time - minus);
        setTimeString(string);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cancelRequest, time]);

  useEffect(() => {
    if (!astroID) return;
    const fetchETA = async () => {
      try {
        const { data } = await axios.get("/api/check-eta/", {
          params: {
            id,
            astro_id: astroID
          }
        });
        if (data?.result?.ETA) {
          const ETA = new Date(data?.result?.ETA || new Date()) - new Date();
          setTime(ETA);
        }
      } catch (error) {
      }
    };
    fetchETA();
  }, [astroID, id]);

  return (
    <div className="toast-message mx-4">
      <div className="toast-message__title">Waiting for Astrologer to accept your {method === 1 ? "chat" : "call"} request. {timeString}</div>
    </div>
  );
};

export default Countdown;