import { combineReducers } from 'redux';
import auth from './auth';
import { dataReducer, changeFaqIndex } from './faq';
import { astrologerDataReducer } from './astrologer';
import { blogsDataReducer } from './blogs';
import { testimonialDataReducer } from './testimonial';

const rootReducer = combineReducers({
  auth,
  data: dataReducer,
  faqIndex: changeFaqIndex,
  astrologerData: astrologerDataReducer,
  blogsData: blogsDataReducer,
  testimonialData: testimonialDataReducer
});

export default rootReducer;
