const initialState = {
    data: null,
    loading: false,
    error: null,
    index: 0,
  };
  
  const testimonialDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DATA_REQUEST_TESTIMONIAL':
        return { ...state, loading: true, error: null };
      case 'FETCH_DATA_SUCCESS_TESTIMONIAL':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_DATA_FAILURE_TESTIMONIAL':
        return { ...state, loading: false, error: action.error };
      default:
        return state;
    }
  };

  export {testimonialDataReducer}
  