import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
// import { updateWallet } from "../redux/actions/auth";
// import { displayRazorpay } from "../components/chatToAstrologer/displayRazorpay";
import { updateWallet } from "../redux/actions/auth";
const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const NEW_CHAT_ALERT_EVENT = "newChatAlert";
const UPDATE_CHAT_TIMER_EVENT = "updateChatTimer";

const useChatRequest = ({ userId, roomId, updateActiveRequest, shouldStart = false }) => {
  const socketRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;
  const [messages, setMessages] = useState([]);
  const [chatTime, setChatTime] = useState(() => ({
    total_chat_time: 0,
    max_chat_time: 0,
    chat_start_time: new Date(),
  }));
  const [showRecharge, setShowRecharge] = useState(() => false);

  const updateShowRecharge = (value = false) => {
    if (value) {
      socketRef?.current.emit(NEW_CHAT_ALERT_EVENT, {
        roomId,
        role: "user",
        datetime: Date.now(),
        isAttachment: false,
        isSuccess: true,
        uploading: false,
        body: "User is recharging his wallet!"
      });
      socketRef?.current.emit("onDisconnect", {
        id: userId,
        role: "user",
        roomId,
        datetime: Date.now(),
      });

      socketRef?.current.disconnect();
    }
    setShowRecharge(value);
  };

  useEffect(() => {
    if (!roomId || !socketRef?.current || !shouldStart) return;
    let interval = null;
    const { max_chat_time, chat_start_time } = chatTime;

    const closeChat = async ({ isInactive = false, datetime = new Date() }) => {
      const room = roomId || "";
      try {
        if (interval) clearInterval(interval);

        const response = await axios.patch(
          "/api/complete-chat-request",
          {
            roomId,
            isInactive,
            datetime,
          },
        );
        if (response?.data?.isSuccess && !isNaN(response?.data?.result?.balance)) {
          dispatch(updateWallet(response?.data?.result?.balance));
          setChatTime({
            total_chat_time: 0,
            max_chat_time: 0,
            chat_start_time: new Date(),
          });

          updateActiveRequest();
        }

        if (isInactive) {
          toast.info("Chat session has been closed due to inactivity!", {
            toastId: "chat-session-closed",
          });
        } else {
          toast.info("Chat session has been closed due to Insufficient Balance!", {
            toastId: "chat-session-closed",
          });
        }
      } catch (error) {
      } finally {
        // socketRef?.current.emit("onDisconnect", {
        //   id: userId,
        //   role: "user",
        //   roomId,
        // });
        setMessages([]);

        updateActiveRequest();
        // socketRef?.current.disconnect();
        navigate("/consult-astro", {
          state: {
            enrollmentId: Number.parseInt(room?.split("_")[0])
          }
        });
      }
    };

    interval = setInterval(() => {
      if (!roomId || !messages || !socketRef.current || !shouldStart) return;
      const lastMessage = messages[messages?.length - 1] || { datetime: chat_start_time.toUTCString() };
    
      const datetime = new Date(lastMessage?.datetime);
    
      const diff = Date.now() - datetime;
    
      const toEndChat = new Date(max_chat_time).getTime() - Date.now();
    
      if (max_chat_time && new Date(max_chat_time) <= new Date()) {
        closeChat({ datetime: max_chat_time });
      } else if (toEndChat > 60_000 && toEndChat < 61_000) {
        updateShowRecharge(true);
      } else if (diff > 180_000) { // Changed from 90_000 to 180_000 for 3 minutes
        closeChat({ isInactive: true });
      } else if (diff > 150_000 && diff < 151_000) { // Changed from 30_000 to 150_000 for 2 minutes 30 seconds
        setMessages((messages) => {
          const filtered = messages?.filter((msg => msg?.role !== "admin"));
          if (pathname !== "/chat") {
            toast.warning("You will be disconnected in 1 minute due to inactivity!", {
              toastId: "chat-session-closed",
            });
          }
          return [
            ...filtered, {
              body: "You will be disconnected in 1 minute due to inactivity!",
              role: "admin",
              datetime: Date.now() - diff,
              msgType: 3
            }];
        });
      }
    }, 1000);
    

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [chatTime, navigate, roomId, userId, messages]);

  useEffect(() => {
    if (!shouldStart) return;

    const closeSession = () => {
      if (!socketRef?.current || !roomId) return;
      socketRef?.current.emit("onDisconnect", {
        id: userId,
        role: "user",
        roomId,
        datetime: Date.now(),
      });
    };

    const openSession = () => {
      if (!socketRef?.current || !roomId) return;
      socketRef?.current.emit("JOIN", {
        role: "user",
        id: userId,
        datetime: Date.now(),
        roomId,
      });
    };

    socketRef.current = socketIOClient("/");
    socketRef.current.on("connect", () => {
      socketRef?.current.emit("JOIN", {
        role: "user",
        id: userId,
        datetime: Date.now(),
        roomId,
      }, ({ result }) => {
        if (result?.role === "admin") {
          setChatTime(() => ({
            total_chat_time: 0,
            max_chat_time: 0,
            chat_start_time: new Date(),
          }));
        } else {
          const { messages, chat_started_on, chat_time_left, total_chat_time } = result;
          const updated = [...messages];
          updated.push({
            body: "Chat resumed!",
            role: "admin",
            datetime: Date.now(),
            msgType: 3,
            hide: true
          });
          setMessages(() => updated || []);
          setChatTime(() => ({
            total_chat_time,
            max_chat_time: Date.now() + chat_time_left,
            chat_start_time: new Date(chat_started_on),
          }));
        }
      });
    });

    window.addEventListener("beforeunload", closeSession);
    window.addEventListener("offline", closeSession);
    // window.addEventListener("pagehide", closeSession);
    window.addEventListener("online", openSession);

    socketRef?.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      setMessages((messages) => {
        const updated = messages.filter(i => i?.role !== "admin");
        return [...updated, message];
      });
    });

    socketRef?.current.on(NEW_CHAT_ALERT_EVENT, (message) => {
      const { isAttachment = false, datetime, state, roomId, result } = message;

      if (state === 4 || result?.balance) {
        toast.info("Chat ended by Astrologer");
        if (result?.balance) {
          dispatch(updateWallet(result?.balance));
        }
        setMessages([]);
        updateActiveRequest();
        navigate("/consult-astro", {
          state: {
            enrollmentId: Number.parseInt(roomId?.split("_")[0])
          }
        });
      }
      const incomingMessage = {
        ...message,
        role: "admin",
      };

      if (isAttachment) {
        if (message?.role === "user") return;
        if (message?.isSuccess) {
          setMessages((messages) => {
            return [...messages].reduce((acc, msg) => {
              if (msg?.uploading && msg?.datetime === datetime) {
                return [...acc, { ...msg, uploading: false }];
              }
              return [...acc, msg];
            }, []);
          });
        } else {
          setMessages((messages) => {
            return messages?.filter((msg) => msg?.datetime !== datetime);
          });
        }
      } else {
        setMessages((messages) => [...messages, incomingMessage]);
      }
    });

    socketRef?.current.on(UPDATE_CHAT_TIMER_EVENT, ({ result }) => {
      const { messages, chat_started_on, chat_time_left, total_chat_time } = result;
      setMessages(messages);
      setChatTime(() => ({
        total_chat_time,
        max_chat_time: Date.now() + chat_time_left,
        chat_start_time: new Date(chat_started_on),
      }));
    });

    return () => {
      window.removeEventListener("beforeunload", closeSession);
      window.removeEventListener("offline", closeSession);
      // window.removeEventListener("pagehide", closeSession);
      window.removeEventListener("online", openSession);

      closeSession();
      socketRef?.current.disconnect();
    };
  }, [userId, roomId, shouldStart]);

  const sendMessage = (messageBody) => {
    socketRef?.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      body: messageBody,
      roomId,
      role: "user",
      datetime: Date.now(),
      isAttachment: false,
    });
  };

  const reloadSocket = () => {
    socketRef?.current.emit("onDisconnect", {
      id: userId,
      role: "user",
      roomId,
      datetime: Date.now(),
    });

    socketRef?.current.disconnect();

    navigate(0);
  };

  const sendUploadedAlert = (datetime, isSuccess) => {
    socketRef?.current.emit(NEW_CHAT_ALERT_EVENT, {
      roomId,
      role: "user",
      datetime,
      isAttachment: true,
      isSuccess,
      uploading: false,
      body: isSuccess ? "Image uploaded successfully!" : "Image upload failed!",
    });
  };

  const sendImage = async (type = "", datetime = Date.now(), file) => {
    try {
      if (!type) return;
      socketRef?.current.emit(NEW_CHAT_MESSAGE_EVENT, {
        attachmentType: type,
        roomId,
        role: "user",
        datetime,
        isAttachment: true,
      }, async ({ result }) => {
        const formData = new FormData();
        formData.append("chat_media", file);
        formData.append("roomId", roomId);
        formData.append("role", "user");
        formData.append("type", type);
        formData.append("path", result?.path);
        const { data } = await axios.post(
          "/api/upload-chat-media",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data && data.isSuccess) {
          setMessages((messages) => {
            return [...messages].reduce((acc, msg) => {
              if (msg?.uploading && msg?.datetime === datetime) {
                return [...acc, { ...msg, uploading: false }];
              }
              return [...acc, msg];
            }, []);
          });
          sendUploadedAlert(datetime, true);
        } else {
          toast.error(data?.message || "Something went wrong while uploading Image!");
          setMessages((messages) => {
            return [...messages].filter((msg) => {
              if (msg?.uploading && msg?.datetime === datetime) {
                return false;
              }
              return true;
            }
            );
          });
          sendUploadedAlert(datetime, false);
        }
      });
    } catch (error) {
      setMessages((messages) => {
        return [...messages].filter((msg) => {
          if (msg?.uploading && msg?.datetime === datetime) {
            return false;
          }
          return true;
        }
        );
      });
      sendUploadedAlert(datetime, false);
    }
  };

  const completeChat = () => {
    setMessages([]);

    socketRef?.current.emit("onDisconnect", {
      id: userId,
      role: "user",
      roomId,
      datetime: Date.now(),
      shouldEnd: true,
    });

    socketRef?.current.disconnect();
  };

  return {
    messages,
    sendMessage,
    roomId,
    sendImage,
    chatTime,
    setChatTime,
    reloadSocket,
    showRecharge,
    updateShowRecharge,
    completeChat
  };
};

export default useChatRequest;
