import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { ErrorBoundary } from "./ErrorBoundry";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useRef } from "react";

const renderError = (error) => {
  let message = "";
  switch (error.name) {
    case "InvalidPDFException":
      message = "The document is invalid or corrupted";
      break;
    case "MissingPDFException":
      message = "The document is missing";
      break;
    case "UnexpectedResponseException":
      message = "Unexpected server response";
      break;
    default:
      message = "Cannot load the document";
      break;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e53e3e",
          borderRadius: "0.25rem",
          color: "#fff",
          padding: "0.5rem",
        }}
      >
        {message}
      </div>
    </div>
  );
};

const PDFViewer = ({ fileUrl = "", close }) => {
  const ref = useRef();
  useOutsideClick(ref, close, !!fileUrl);
  return (
    <ErrorBoundary>
      <div
        ref={ref}
        style={{
          backgroundColor: "#fff",

          /* Fixed position */
          left: 0,
          right: 0,
          position: "fixed",
          top: 0,
          bottom: 0,
          margin: "auto",

          /* Take full size */
          height: "auto",
          maxHeight: "80vh",
          width: "80%",
          maxWidth: "800px",

          /* Displayed on top of other elements */
          zIndex: 9999,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Viewer fileUrl={fileUrl} renderError={renderError} />;
      </div>
    </ErrorBoundary>
  );
};

export default PDFViewer;
