import { ADD_USER, REMOVE_USER, UPDATE_USER, UPDATE_WALLET } from "../constants";

const user = localStorage.getItem("user") || false;

const initialState = {
  isAuthenticated: !!user,
  user: user ? JSON.parse(user) : { id: false },
};

export default function auth(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: { ...state?.user, ...payload },
      };

    case REMOVE_USER:
      return {
        ...state,
        user: false,
        isAuthenticated: false,
      };

    case UPDATE_USER:
      const updatedState = {
        ...state,
        user: { ...state?.user, ...payload },
      };

      localStorage.setItem("user", JSON.stringify(updatedState.user));
      return updatedState;

    case UPDATE_WALLET:
      const amount = Number.parseFloat(payload).toFixed(2);
      const balance = isNaN(amount) ? 0 : amount;
      const newState = {
        ...state,
        user: { ...state?.user, balance },
      };

      localStorage.setItem("user", JSON.stringify(newState.user));

      return newState;

    default:
      return state;
  }
}
