import React, { useState, useRef, useEffect, Fragment } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createPortal } from 'react-dom';
import Chatbar from './ChatBar';
import PDFViewer from '../chatToAstrologer/PDF/PDFViewer';
import { fromNow } from '../chatToAstrologer/fromnow';

function ChatHistory({ profile }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPDF, setShowPDF] = useState(() => '');
  const { astroId, enrollmentId } = state || {};
  const [messages, setMessages] = useState([]);

  const [astrologer, setAstrologer] = useState({});

  useEffect(() => {
    if (!enrollmentId) return;
    const getChatHistory = async () => {
      const { data } = await axios.get(
        `/api/get-chat-data?enrollment_id=${enrollmentId}`
      );
      if (data?.isSuccess) {
        setMessages(data?.result || []);
      }
    };
    getChatHistory();
  }, [enrollmentId]);

  useEffect(() => {
    if (!enrollmentId || !astroId) navigate('/consult-astro');
  }, [navigate, enrollmentId, astroId]);

  const chatScrollRef = useRef();

  useEffect(() => {
    if (!astroId) return;
    const getAstrologer = async () => {
      const { data } = await axios.get(`/api/astro-profile/${astroId}`);
      if (data) {
        setAstrologer(data);
      }
    };
    getAstrologer();
  }, [astroId]);

  useEffect(() => {
    if (chatScrollRef) {
      chatScrollRef.current.scrollTop = chatScrollRef?.current?.scrollHeight;
    }
  }, [messages]);

  return (
    <div style={{ background: '#52575D1A' }}>
      <Chatbar astrologer={astrologer} />

      {showPDF ? (
        createPortal(
          <PDFViewer fileUrl={showPDF} close={() => setShowPDF('')} />,
          document.body
        )
      ) : (
        <></>
      )}

      <MDBContainer>
        <MDBCard style={{ paddingBottom: '20px', marginTop: '5px' }}>
          <MDBRow
            className="chat_container"
            style={{ height: '87vh', overflow: 'hidden' }}
          >
            <MDBCol
              className="px-0"
              size="12"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                maxHeight: '86vh',
                overflow: 'auto',
              }}
            >
              <div
                className="messages-container disable-scrollbars"
                style={{
                  overflow: 'auto',
                  paddingTop: '10px',
                  height: '100%',
                  maxHeight: 'unset',
                }}
                ref={chatScrollRef}
              >
                <div className="chat" style={{ borderBottom: 'none' }}>
                  <ul style={{ padding: '0' }} className="messages-list">
                    {/* \*messages*\ */}
                    {messages
                      ?.filter((i) => !i?.hide)
                      ?.map((message, i) => (
                        <li
                          key={i}
                          className={`message-item ${
                            message?.role !== 'admin'
                              ? message?.role === 'user'
                                ? 'my-message'
                                : 'received-message'
                              : 'admin-message'
                          }`}
                        >
                          <div className="chat-header clearfix">
                            {message?.role !== 'admin' ? (
                              message?.role === 'user' ? (
                                <div className="chat-about">
                                  <div className="chat-num-messages">
                                    <span>You,</span>
                                    {fromNow(message?.datetime)}
                                  </div>
                                  <img
                                    src={
                                      profile ||
                                      `${process.env.PUBLIC_URL}/images/no-profile.jpg`
                                    }
                                    alt="avatar"
                                    style={{
                                      width: '40px',
                                      borderRadius: '50%',
                                      height: '40px',
                                    }}
                                    loading="lazy"
                                  />
                                </div>
                              ) : (
                                <div className="chat-about">
                                  <img
                                    src={astrologer.profile}
                                    alt={astrologer.name}
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      borderRadius: '50%',
                                    }}
                                    loading="lazy"
                                  />
                                  <div className="chat-num-messages">
                                    <span>{astrologer.name},</span>{' '}
                                    {fromNow(message?.datetime)}
                                  </div>
                                </div>
                              )
                            ) : (
                              <></>
                            )}

                            <div className="message_body">
                              {message?.isAttachment && message.uploading ? (
                                <div className="loading">
                                  <progress></progress>
                                </div>
                              ) : (
                                <></>
                              )}
                              {message?.isAttachment && !message.uploading ? (
                                message?.attachmentType?.startsWith('PDF') ? (
                                  <div
                                    onClick={() =>
                                      setShowPDF(message?.body || message?.data)
                                    }
                                  >{`Open ${
                                    message?.body || message?.data
                                  }`}</div>
                                ) : (
                                  <img
                                    src={message?.body || message?.data}
                                    style={{ maxWidth: '300px' }}
                                    alt={message?.attachmentType}
                                    loading="true"
                                  />
                                )
                              ) : message?.uploading ? (
                                message?.role === 'user' ? (
                                  'Uploading'
                                ) : (
                                  'Downloading'
                                )
                              ) : (
                                message?.body || message?.data
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default ChatHistory;
