import { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            backgroundColor: "#fff",

            /* Fixed position */
            left: 0,
            right: 0,
            position: "fixed",
            top: 0,
            bottom: 0,
            margin: "auto",

            /* Take full size */
            height: "auto",
            maxHeight: "80vh",
            width: "80%",
            maxWidth: "800px",

            /* Displayed on top of other elements */
            zIndex: 9999,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#e53e3e",
                borderRadius: "0.25rem",
                color: "#fff",
                padding: "0.5rem",
              }}
            >
              Error loading PDF file! Please try again later.
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
