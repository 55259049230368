import { Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
// import useChatRequest from "./hooks/useChatRequest";
import useChatStatus from './hooks/useChatStatus';
import { Suspense, lazy, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loading from './components/Loading';
import useInterceptor from './hooks/useInterceptor.js';
import { usePageTracking } from './hooks/usePageTracking';
import useChatRequest from './hooks/useChatRequest';
import ChatHistory from './components/chatHistory/ChatHistory';
import axios from 'axios';

const Home = lazy(() => import('./components/home/Home.js'));
const TalktoAstrologer = lazy(() =>
  import('./components/talkToAstrologer/TalkToAstrologer.js')
);
const TermsCondition = lazy(() => import('./components/pages/TermsCondition'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const AstrologerProfile = lazy(() =>
  import('./components/astrologerProfile/AstrologerProfile.js')
);
const ChatToAstro = lazy(() =>
  import('./components/ChatToAstro/ChatToAstro.js')
);
const Dashboard = lazy(() => import('./components/dashboard/Dashboard.js'));
const Wallet = lazy(() => import('./components/dashboard/Wallet.js'));
const History = lazy(() => import('./components/dashboard/History.js'));
const Setting = lazy(() => import('./components/dashboard/Setting.js'));
const ChatToAstrologer = lazy(() =>
  import('./components/chatToAstrologer/chattoastrologer.js')
);
const AstroAccept = lazy(() => import('./components/AstroAccept.jsx'));
const Chat = lazy(() => import('./components/chatToAstrologer/chat.js'));
const Astro = lazy(() => import('./components/astro/Astro.jsx'));
const Faq = lazy(() => import('./components/faq/Faq.jsx'));
const Blogs = lazy(() => import('./components/blogs/blogs.js'));
const Blog = lazy(() => import('./components/blogs/blog.js'));
const AboutUs = lazy(() => import('./components/pages/About.js'));
const AdminPage = lazy(() => import('./pages/admin/Admin.jsx'));

function App() {
  // axios.defaults.baseURL = 'https://46da-2401-4900-1f30-44ff-e085-8f55-800c-ce45.ngrok-free.app';
  // axios.defaults.baseURL = 'http://dev.consultmyastro.com';
  const appContext = useSelector((state) => state.auth);
  const {
    activeRequest,
    updateActiveRequest,
    roomID,
    setRoomID,
    isChatPage,
    endChat,
    navigateToChat,
  } = useChatStatus(appContext);

  const { ...props } = useChatRequest({
    userId: appContext.user.id,
    roomId: roomID,
    updateActiveRequest,
    token: appContext.user.accessToken,
    shouldStart: !!activeRequest.isActive,
  });

  useInterceptor(toast);
  usePageTracking();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/astro" element={<Astro />} />
        <Route path="/astro/:astroId" element={<Astro />} />

        <Route path="/" element={<Home />} />

        <Route
          path="/consult-astro"
          element={
            <TalktoAstrologer
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/dashboard"
          element={
            appContext?.isAuthenticated ? (
              <Dashboard
                user={appContext?.user}
                currentAstro={activeRequest.astro}
                method={activeRequest.type}
                navigateToChat={navigateToChat}
                isActive={activeRequest.isActive}
              />
            ) : (
              <Home
                currentAstro={activeRequest.astro}
                method={activeRequest.type}
                navigateToChat={navigateToChat}
                isActive={activeRequest.isActive}
              />
            )
          }
        />

        <Route
          path="/wallet"
          element={
            <Wallet
              user={appContext?.user}
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/history"
          element={
            <History
              user={appContext?.user}
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/setting"
          element={
            <Setting
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/astrologerprofile/:id"
          element={
            <AstrologerProfile
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/ChatToAstro/:id"
          element={
            <ChatToAstro
              user={appContext?.user}
              activeRequest={activeRequest}
              updateActiveRequest={updateActiveRequest}
              currentAstro={activeRequest.astro}
              method="Chat"
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/CallToAstro/:id"
          element={
            <ChatToAstro
              user={appContext?.user}
              activeRequest={activeRequest}
              updateActiveRequest={updateActiveRequest}
              method="Call"
              currentAstro={activeRequest.astro}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/chattoastrologer/:chat_id"
          element={<ChatToAstrologer />}
        />

        <Route
          path="/chat"
          element={
            <Chat
              userId={appContext.user.id}
              token={appContext.user.accessToken}
              walletAmount={appContext.user.balance}
              endChat={endChat}
              profile={appContext.user.profile_image}
              updateActiveRequest={updateActiveRequest}
              setRoomID={setRoomID}
              {...props}
            />
          }
        />

        <Route
          path="/chat-history/"
          element={
            <ChatHistory
              userId={appContext.user.id}
              token={appContext.user.accessToken}
              walletAmount={appContext.user.balance}
              endChat={endChat}
              profile={appContext.user.profile_image}
              updateActiveRequest={updateActiveRequest}
              setRoomID={setRoomID}
            />
          }
        />
        <Route
          path="/faq/:slug"
          element={
            <Faq
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />
        <Route
          path="/blogs"
          element={
            <Blogs
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />
        <Route
          path="/blog/:slug"
          element={
            <Blog
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />
        <Route
          path="/about-us"
          element={
            <AboutUs
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route
          path="/admin"
          element={
            <AdminPage
              currentAstro={activeRequest.astro}
              method={activeRequest.type}
              navigateToChat={navigateToChat}
              isActive={activeRequest.isActive}
            />
          }
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
      </Routes>

      <ToastContainer
        theme="dark"
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />

      {!!roomID &&
      !isChatPage &&
      !!activeRequest.id &&
      !activeRequest.isActive ? (
        <AstroAccept
          open={true}
          setOpen={setRoomID}
          accessToken={appContext.user.accessToken}
          roomID={roomID}
          updateActiveRequest={updateActiveRequest}
          activeRequest={activeRequest}
        />
      ) : (
        <></>
      )}
    </Suspense>
  );
}

export default App;
