const initialState = {
    data: null,
    loading: false,
    error: null,
    index: 0,
  };
  

const blogsDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DATA_REQUEST_BLOGS':
        return { ...state, loading: true, error: null };
      case 'FETCH_DATA_SUCCESS_BLOGS':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_DATA_FAILURE_BLOGS':
        return { ...state, loading: false, error: action.error };
      default:
        return state;
    }
  };

  export {blogsDataReducer}