// reducers.js
const initialState = {
  data: null,
  loading: false,
  error: null,
  index: 0,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATA_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_DATA_SUCCESS':
      return { ...state, loading: false, data: action.payload, error: null };
    case 'FETCH_DATA_FAILURE':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

const changeFaqIndex = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_FAQ_INDEX':
      return { ...state, index: action.index };
    default:
      return state;
  }
};

export { dataReducer, changeFaqIndex };
