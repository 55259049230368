// reducers.js
const initialState = {
    data: null,
    loading: false,
    error: null,
    index: 0,
  };
  
  const astrologerDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DATA_REQUEST_ASTROLOGER':
        return { ...state, loading: true, error: null };
      case 'FETCH_DATA_SUCCESS_ASTROLOGER':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_DATA_FAILURE_ASTROLOGER':
        return { ...state, loading: false, error: action.error };
      default:
        return state;
    }
  };

  export {astrologerDataReducer}