import setAuthToken from "../../components/helper/setAuthToken";
import { ADD_USER, REMOVE_USER, UPDATE_USER, UPDATE_WALLET } from "../constants";
import axios, { get } from "axios";
// import { toast } from "react-toastify";

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const addUser = (payload) => {
  return {
    type: ADD_USER,
    payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload,
  };
};

export const updateWallet = (payload) => {
  return {
    type: UPDATE_WALLET,
    payload,
  };
};

export const deleteLocal = () => {
  return async () => {
    localStorage.removeItem("user");
  };
};

export const logoutUser = async () => {
  return async (dispatch) => {
    await axios.delete("/api/auth/logout");
    dispatch(removeUser());
    dispatch(deleteLocal());
    setAuthToken(false);
    window.location.href = "/";
  };
};

const parseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const restoreSession = () => {
  return async (dispatch) => {
    const userData = localStorage.getItem("user") || null;
    if (!userData) return;

    const userJSON = parseJSON(userData);
    if (!userJSON || !userJSON?.accessToken) {
      return dispatch(logoutUser());
    }

    const user = await get("/api/auth/restore-session", {
      headers: {
        withCredentials: true,
        validateStatus: true,
        Authorization: `Bearer ${userJSON?.accessToken}`,
      },
    });
    if (user?.data && user?.data?.isSuccess) {
      setAuthToken(user.data?.result?.accessToken);
      dispatch(addUser(user.data?.result));
      localStorage.setItem("user", JSON.stringify(user?.data?.result));
    } else dispatch(logoutUser());
  };
};

export const checkCurrentAstro = ({ astro_id, currentAstro, toast }) => {
  if (Number.parseInt(currentAstro) === Number.parseInt(astro_id)) {
    toast.info("Waiting for response! Please wait...", {
      toastId: "waiting",
    });
  } else toast.info(
    "Please cancel the previous request! You can only talk to one astrologer at a time.", {
    toastId: "cancelPreviousRequest",
  }
  );
};
