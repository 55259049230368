import { MDBNavbar, MDBNavbarBrand, MDBContainer } from "mdb-react-ui-kit";

const Chatbar = ({ astrologer, chatTime }) => {
  return (
    <div
      style={{
        background: "#fff",
        boxShadow:
          "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
      }}
    >
      <MDBContainer>
        <MDBNavbar
          dark={false}
          expand="md"
          style={{
            boxShadow: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            listStyle: "none",
          }}
          fixed
        >
          <MDBNavbarBrand>
            {/* onClick={()=>setShowMenu(!showMenu)} */}
            <div
              className="chat"
              style={{ width: "auto", borderBottom: "none" }}
            >
              <div
                className=" d-flex clearfix align-items-center"
                style={{ padding: "0px" }}
              >
                <img
                  src={astrologer?.profile}
                  alt={astrologer?.name}
                  style={{ width: "40px" }}
                />
                <div className="chat-about mt-0">
                  <b
                    className="chat-num-messages font-weight-bold"
                    style={{ color: "#000", fontSize: "14px" }}
                  >
                    {astrologer?.name}{" "}
                  </b>
                  <div
                    className="chat-num-messages"
                    style={{ fontSize: "12px" }}
                  >
                    Chat Ended{" "}
                    {chatTime ? (
                      <b className="font-weight-bold" styel={{ color: "#000" }}>
                        {chatTime} Min
                      </b>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </MDBNavbarBrand>
        </MDBNavbar>
      </MDBContainer>
    </div>
  );
};

export default Chatbar;
