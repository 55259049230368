import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { restoreSession } from "./redux/actions/auth";
import { Worker } from "@react-pdf-viewer/core";

// Restore session before rendering
store.dispatch(restoreSession());

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// ! only change worker version if changes in dependency version

root.render(
  <StrictMode>
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.2.146/pdf.worker.min.js">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Worker>
  </StrictMode> // Pass the root element to render the app
);
