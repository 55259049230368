import axios from "axios";
const setAuthToken = (token = false) => {
  if (token) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
  } else {
    axios.defaults.withCredentials = false;
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;