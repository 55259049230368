import axios from 'axios';
import setAuthToken from '../components/helper/setAuthToken';
import { removeUser } from '../redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useInterceptor = (toast = {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;

    axios.interceptors.response.use(
      function (response) {
        return response;
      },

      function (error) {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          toast?.error(error?.response?.data?.message || "Session expired, please login again", {
            position: toast?.POSITION?.TOP_CENTER,
            toastId: "sessionExpired",
            autoClose: 3000,
          });
          setAuthToken(false);
          dispatch(removeUser());
          localStorage.removeItem('user');
          navigate("/consult-astro");
        } else return error?.response;
      }
    );

    return () => {
      axios.interceptors.response.eject();
    };
  }, [dispatch, navigate, toast]);
};

export default useInterceptor;